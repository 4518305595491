import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import ScrollToTop from "react-scroll-to-top";

import RouteScrollToTop from "./elements/RouteScrollToTop";

// HOME
import Home from "./pages/Home";

// PAGES
// import About from "./pages/About";
// import Service from "./pages/Service";
// import ServiceDetails from "./pages/ServiceDetails";
// import Faq from "./pages/Faq";
import Contact from "./pages/Contact";

// EXTRA
// import HomeTwo from "./pages/HomeOne";
// import HomeThree from "./pages/HomeThree";
// import HomeFour from "./pages/HomeFour";
// import HomeFive from "./pages/HomeFive";
// import Pricing from "./pages/Pricing";


function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path='/' element={<Home />} />
        {/* <Route exact path='/about' element={<About />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/service-details' element={<ServiceDetails />} />
        <Route exact path='/faq' element={<Faq />} /> */}
        <Route exact path='/contact' element={<Contact />} />

        {/* Extras */}
        {/* <Route exact path='/home-2' element={<HomeTwo />} />
        <Route exact path='/home-3' element={<HomeThree />} />
        <Route exact path='/home-4' element={<HomeFour />} />
        <Route exact path='/home-5' element={<HomeFive />} /> */}
        {/* <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} /> */}
        {/* <Route exact path='/pricing' element={<Pricing />} /> */}
      </Routes>
      <ScrollToTop smooth color='#FA4318' />
    </BrowserRouter>
  );
}

export default App;
